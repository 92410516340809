// src/redux/actions/customerActions.js

export const loginCustomer = (userData, navigate) => {
  return async (dispatch) => {
      try {
          const response = await fetch('/login', {
              method: 'POST',
              body: JSON.stringify(userData),
              headers: {
                  'Content-Type': 'application/json',
              },
          });

          const data = await response.json();

          // Dispatch the success action with the response data
          if (response.ok) {
              dispatch({
                  type: 'LOGIN_SUCCESS',
                  payload: data,
              });
              navigate('/checkout'); // Navigate to checkout or another page on success
          } else {
              throw new Error(data.message || 'Login failed');
          }
      } catch (error) {
          dispatch({
              type: 'LOGIN_FAILURE',
              payload: error.message,
          });
      }
  };
};

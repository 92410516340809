import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from './Header';
import Navbar from './Navbar';
import axios from 'axios';
import Categories from './Categories';
import Footer from './Footer';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { ADD, DLT, REMOVE } from '../redux/actions/action';
import Swal from 'sweetalert2';
import { BASE_URL } from '../Config';
function AllProducts() {
    const { id } = useParams();
    const token = localStorage.getItem('token');
    const [productList, setProductList] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${BASE_URL}/api/product_list`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setProductList(response.data.product_list);
            } catch (error) {
                console.error('Error fetching product data:', error);
            }
        };
        fetchData();
    }, [id, token, dispatch]);

    const send = (product) => {
        Swal.fire({
            icon: 'success',
            title: `${product.product_name} Item Added to Cart`,
            text: 'Successfully!',
        });
        dispatch(ADD(product));
    };

    return (
        <>
            <Header />
            <Navbar />
            <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h2>Women's</h2>
                        <ul>
                            <li><a href="index.html">Home</a></li>
                            <li>Products</li>
                        </ul>
                    </div>
                </div>
            </div>
            <section className="products-area pt-100 pb-70">
                <div className="container">
                    <div className="products-filter-options">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-4 col-md-4">
                                <div className="d-lg-flex d-md-flex align-items-center">
                                    <span className="sub-title">
                                        <a href="#" data-bs-toggle="modal" data-bs-target="#productsFilterModal">
                                            <i className='bx bx-filter-alt'></i> Filter
                                        </a>
                                    </span>
                                    <span className="sub-title d-none d-lg-block d-md-block">View:</span>
                                    <div className="view-list-row d-none d-lg-block d-md-block">
                                        <div className="view-column">
                                            <a href="#" className="icon-view-two">
                                                <span></span><span></span>
                                            </a>
                                            <a href="#" className="icon-view-three active">
                                                <span></span><span></span><span></span>
                                            </a>
                                            <a href="#" className="icon-view-four">
                                                <span></span><span></span><span></span><span></span>
                                            </a>
                                            <a href="#" className="view-grid-switch">
                                                <span></span><span></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <p>Showing 1 – 18 of 100</p>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="products-ordering-list">
                                    <select>
                                        <option>Sort by Price: Low to High</option>
                                        <option>Default Sorting</option>
                                        <option>Sort by Popularity</option>
                                        <option>Sort by Average Rating</option>
                                        <option>Sort by Latest</option>
                                        <option>Sort by Price: High to Low</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="products-collections-filter" className="row">
                        {productList.map((product, index) => (
                            <div key={index} className="col-lg-4 col-md-6 col-sm-6 products-col-item">
                                <div className="single-products-box">
                                    <div className="products-image">
                                        <a href="#">
                                            <img
                                                src={`${BASE_URL}/uploads/products/${product.images[0]}`}
                                                className="main-image"
                                                alt="image"
                                                style={{ height: '450px', width: '350px' }}
                                            />
                                            <img
                                                src={`${BASE_URL}/uploads/products/${product.images[1]}`}
                                                className="hover-image"
                                                style={{ height: '450px', width: '350px' }}
                                                alt="image"
                                            />
                                        </a>
                                    </div>
                                    <div className="products-content">
                                        <h3><a href="#">{product.product_name}</a></h3>
                                        <div className="price">
                                            <span className="new-price">Rs. {product.price}</span>
                                        </div>
                                        <Button variant="primary" onClick={() => send(product)} className='col-lg-12'>
                                            Add to Cart
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="pagination-area text-center">
                        <a href="#" className="prev page-numbers"><i className='bx bx-chevron-left'></i></a>
                        <span className="page-numbers current" aria-current="page">1</span>
                        <a href="#" className="page-numbers">2</a>
                        <a href="#" className="page-numbers">3</a>
                        <a href="#" className="page-numbers">4</a>
                        <a href="#" className="page-numbers">5</a>
                        <a href="#" className="next page-numbers"><i className='bx bx-chevron-right'></i></a>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default AllProducts;

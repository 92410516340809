import React from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
export default function LeftSidebar({ isSidebarOpen, toggleSidebar }) {
    const location = useLocation();
    return (
        <>
            <ul className={isSidebarOpen ? 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled' : 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion'} id="accordionSidebar">
                <Link className="sidebar-brand d-flex align-items-center justify-content-center mt-3" to="/adminhome" >
                    <div className='row'>
                        <div className='col-md-10'>
                            <div className="sidebar-brand-text-img">
                                Eazy BreezyU
                            </div>
                        </div>
                    </div>
                </Link>
                <hr className="sidebar-divider my-0" />
                <li className={`nav-item ${location.pathname === '/admin' ? 'active' : ''}`} >
                    <Link className="nav-link" to="/admin">
                        <i className="fas fa-fw fa-home"></i>
                        <span>Dashboard</span>
                    </Link>
                </li>
                <li className={`nav-item ${location.pathname.startsWith('/customer') ? 'active' : ''}`} style=  {{marginTop:'-10px'}}>
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#masterMenu"
                        aria-expanded="true" aria-controls="collapseThree">
                        <i className="fas fa-fw fa-key"></i>
                        <span>Master</span>
                    </a>
                    
                    <div id="masterMenu" className={`collapse ${location.pathname.startsWith('/category-list') ? 'show' : ''}`} aria-labelledby="headingThree">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <NavLink className={`collapse-item ${location.pathname === '/category-list' ? 'active' : ''}`} to="/category-list">Category</NavLink>
                        </div>
                    </div>
                    <div id="masterMenu" className={`collapse ${location.pathname.startsWith('/category-sub-list') ? 'show' : ''}`} aria-labelledby="headingThree">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <NavLink className={`collapse-item ${location.pathname === '/sub-category-list' ? 'active' : ''}`} to="/category-sub-list">Sub Category</NavLink>
                        </div>
                    </div>
                    
                    <div id="masterMenu" className={`collapse ${location.pathname.startsWith('/product_list') ? 'show' : ''}`} aria-labelledby="headingThree">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <NavLink className={`collapse-item ${location.pathname === '/product_list' ? 'active' : ''}`} to="/product_list">Product</NavLink>
                        </div>
                    </div>

                    <div id="masterMenu" className={`collapse ${location.pathname.startsWith('/size_unit') ? 'show' : ''}`} aria-labelledby="headingThree">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <NavLink className={`collapse-item ${location.pathname === '/size_unit' ? 'active' : ''}`} to="/size_unit">Unit</NavLink>
                        </div>
                    </div>
                    <div id="masterMenu" className={`collapse ${location.pathname.startsWith('/size_unit') ? 'show' : ''}`} aria-labelledby="headingThree">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <NavLink className={`collapse-item ${location.pathname === '/size_unit' ? 'active' : ''}`} to="/size_unit">Size</NavLink>
                        </div>
                    </div>
                </li>
                <li className={`nav-item ${location.pathname.startsWith('/customer') ? 'active' : ''}`} style=  {{marginTop:'-10px'}}>
                    <a className="nav-link collapsed" href="*" data-toggle="collapse" data-target="#customerMenu"
                        aria-expanded="true" aria-controls="collapseThree">
                        <i className="fas fa-area-chart"></i>
                        <span>Inventory</span>
                    </a>
                    <div id="customerMenu" className={`collapse ${location.pathname.startsWith('/customer') ? 'show' : ''}`} aria-labelledby="headingThree">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <NavLink className={`collapse-item ${location.pathname === '/customer' ? 'active' : ''}`} to="/customer">Add Quantity</NavLink>
                        </div>
                    </div>
                    <div id="customerMenu" className={`collapse ${location.pathname.startsWith('/customer') ? 'show' : ''}`} aria-labelledby="headingThree">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <NavLink className={`collapse-item ${location.pathname === '/customer' ? 'active' : ''}`} to="/customer">Show Quantity</NavLink>
                        </div>
                    </div>
                    
                </li>
                <li className={`nav-item ${location.pathname.startsWith('/customer') ? 'active' : ''}`} style=  {{marginTop:'-10px'}}>
                    <a className="nav-link collapsed" href="*" data-toggle="collapse" data-target="#customerMenu"
                        aria-expanded="true" aria-controls="collapseThree">
                        <i className="fas fa-fw fa-user"></i>
                        <span>Customer</span>
                    </a>
                    <div id="customerMenu" className={`collapse ${location.pathname.startsWith('/customer') ? 'show' : ''}`} aria-labelledby="headingThree">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <NavLink className={`collapse-item ${location.pathname === '/customer' ? 'active' : ''}`} to="/customer">Customer</NavLink>
                        </div>
                    </div>
                    
                </li>
                <li className={`nav-item ${location.pathname.startsWith('/customer') ? 'active' : ''}`} style=  {{marginTop:'-10px'}}>
                    <a className="nav-link collapsed" href="*" data-toggle="collapse" data-target="#orderMenu"
                        aria-expanded="true" aria-controls="collapseThree">
                        <i className="fas fa-fw fa-list"></i>
                        <span>Order</span>
                    </a>
                   
                    <div id="orderMenu" className={`collapse ${location.pathname.startsWith('/order_list') ? 'show' : ''}`} aria-labelledby="headingThree">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <NavLink className={`collapse-item ${location.pathname === '/order_list' ? 'active' : ''}`} to="/order_list">Order</NavLink>
                        </div>
                    </div>
                </li>
              
                <li className={`nav-item ${location.pathname === '/settings' ? 'active' : ''}`} style={{marginTop:'-10px'}}>
                    <Link className="nav-link" >
                        <i className="fas fa-fw fa-wrench"></i>
                        <span>Settings</span>
                    </Link>
                </li>
                <hr className="sidebar-divider d-none d-md-block" />
                <div className="row">
                    <div className='col-md-12 d-flex justify-content-center '>
                        <div className="text-center d-none d-md-inline">
                            <button className="rounded-circle border-0" id="sidebarToggle" onClick={toggleSidebar}></button>
                        </div>
                    </div>
                </div>
            </ul>
        </>

    )
}

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from "sweetalert2";

const Payment = () => {
    const cartItems = useSelector((state) => state.cartreducer.carts);
    const [paymentMethod, setPaymentMethod] = useState('COD'); // Default to COD

    const calculateTotal = () => {
        return cartItems.reduce((total, item) => total + item.price * item.qnty, 0);
    };

    const handleCheckout = () => {
        // Simulate successful COD payment processing
        Swal.fire({
            icon: "success",
            title: "Order Placed Successfully",
            text: "Thank you! Your order will be delivered to you in cash on delivery.",
        });

        // Clear the cart after successful order placement
        // Add your dispatch logic here if needed
    };

    return (
        <div className="payment-container">
            <h2>Payment</h2>
            <p>Total Amount: Rs. {calculateTotal()}</p>
            <div>
                <h4>Select Payment Method:</h4>
                <label>
                    <input
                        type="radio"
                        value="COD"
                        checked={paymentMethod === 'COD'}
                        onChange={() => setPaymentMethod('COD')}
                    />
                    Cash on Delivery
                </label>
            </div>
            <button onClick={handleCheckout}>
                Place Order
            </button>
        </div>
    );
};

export default Payment;

// src/redux/reducers/customerReducer.js

// Define the initial state with default values
const initialState = {
    isAuthenticated: false,
    name: '',
    email: '',
    orders: [],
    error: null, // Initialize error as null to avoid undefined issues
};

// Define the reducer function
const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                isAuthenticated: true,
                name: action.payload.name,
                email: action.payload.email,
                error: null, // Clear any existing errors on successful login
            };
        
            case 'LOGIN_FAILURE':
                return {
                    ...state,
                    error: action.payload, // Set error message directly
                };

        case 'LOGOUT':
            return initialState; // Reset state on logout

        default:
            return state;
    }
};

export default customerReducer;

import React from 'react';
import { Link, NavLink } from 'react-router-dom';

function Footer() {
  return (
    <>
    <footer className="main-footer">
            <div className="footer-top">
                <div className="auto-container">
                    <div className="row clearfix">
                        
                          
                                <div className="col-lg-3 col-md-3 col-sm-12 footer-column">
                                    <div className="footer-widget logo-widget">
                                        <figure className="footer-logo"><a href="index.html"><img src="../assets_web/images/logo.png" alt="" /></a></figure>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12 footer-column">
                                    <div className="footer-widget links-widget">
                                        <div className="widget-title">
                                            <h3>Quick Links</h3>
                                        </div>
                                        <div className="widget-content">
                                            <ul className="links-list clearfix">
                                                <li><Link to={`/`}>Home </Link></li>
                                                <li><Link to={`/about`}>About</Link></li>
                                                <li><Link to={`/contact-us`}>Contact</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12 footer-column">
                                    <div className="footer-widget links-widget">
                                        <div className="widget-title">
                                            <h3>Useful Links</h3>
                                        </div>
                                        <div className="widget-content">
                                            <ul className="links-list clearfix">
                                                <li><Link to="/privacy-policy">Privacy Policy </Link></li>
                                                <li><Link to="/terms-condition">Terms of Use</Link></li>
                                                <li><Link to="/shipping-returns">Shipping & Returns</Link></li>
                                                
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12 footer-column">
                                    <div className="footer-widget contact-widget">
                                        <div className="widget-title">
                                            <h3>Contact</h3>
                                        </div>
                                        <ul className="info-list clearfix">
                                            <li>Key Solutions, 208, First Floor, <br/>Jaipuria Plaza, Sector - 26,<br/> Noida - 201301, INDIA</li>
                                            <li><a href="tel:23055873407">+880171889547</a></li>
                                            <li><a href="mailto:info@example.com">info@ezzybrezzyu.com</a></li>
                                        </ul>
                                       
                                    </div>
                                </div>
                            
                        
                        
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="auto-container clearfix">
                    <ul className="cart-list pull-left clearfix">
                        <li><a href="index.html"><img src="assets_web/images/resource/card-1.png" alt="" /></a></li>
                        <li><a href="index.html"><img src="assets_web/images/resource/card-2.png" alt="" /></a></li>
                        <li><a href="index.html"><img src="assets_web/images/resource/card-3.png" alt="" /></a></li>
                        <li><a href="index.html"><img src="assets_web/images/resource/card-4.png" alt="" /></a></li>
                    </ul>
                    <div className="copyright pull-right">
                        <p>&copy; 2024 All Right Reserved</p>
                    </div>
                </div>
            </div>
        </footer>
        <button className="scroll-top scroll-to-target" data-target="html">
            <i className="fas fa-long-arrow-alt-up"></i>
        </button>
    
    </>
  )
}

export default Footer
import React from 'react'
import Header from './Header'
import Footer from './Footer'
function ShippingReturns() {
  return (
  <>
  <Header />
  <section className="page-title centred"  style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets_web/images/background/page-title.jpg)`}}>
            <div className="pattern-layer"></div>
            <div className="auto-container">
                <div className="content-box">
                    <h1>Shipping & Returns</h1>
                    <ul className="bread-crumb clearfix">
                        <li><i className="flaticon-home-1"></i><a href="index.html">Home</a></li>
                        <li>Shipping & Returns</li>
                    </ul>
                </div>
            </div>
        </section>
        <section className="about-section">
      <div className="auto-container">
        <div className="row align-items-center clearfix">
          <div className="col-lg-12 col-md-12 col-sm-12 text-column">
            <div className="privacy-section">
              <div className="policy">
                <h5 className="intro-heading three">Shipping and Returns</h5>
                <p className="policy-details">
                  At EezybreezyU, we take great care in delivering the finest quality of 100% pure and natural home and car air fresheners to your doorstep hassle-free. Our dedication to delivering finest quality products to your doorstep, wrapped in meticulously crafted packaging, is a hallmark of our brand. Our team of experts handpicks each design displayed, which undergoes rigorous quality checks before it embarks on its journey to you. As we maintain the highest standards, cancellations or returns are accepted only in rare cases.
                </p>
                <div className="policy-features">
                  <h5 className="intro-heading three">Trademarks</h5>
                  <ul>
                    <li>
                      <p>We offer free shipping across India for orders above Rs. 500. Standard shipping charges apply for orders below this value.</p>
                    </li>
                    <li>
                      <p>Domestic orders typically take 3-7 working days for delivery.</p>
                    </li>
                    <li>
                      <p>We accept various online payment methods, such as Net Banking, Credit and Debit Cards, Bank Transfers, Wallets, and UPI. Please note:- Cash on Delivery is not available.</p>
                    </li>
                    <li>
                      <p>In exceptional cases such as natural disasters, public holidays, or unforeseeable events beyond our control, EezybreezyU cannot be held liable for return or exchange. However, we will keep you updated on your order's status.</p>
                    </li>
                    <li>
                      <p>Please note that the mentioned delivery days are estimates, reliant on our courier partners.</p>
                    </li>
                    <li>
                      <p>Returns will be accepted only in cases of manufacturing defects, damages, or if an incorrect item has been delivered to you. Ensure the returned product retains its original, unused condition along with tags and the original bill.</p>
                    </li>
                    <li>
                      <p>Item/s must not have been used or altered in any way.</p>
                    </li>
                    <li>
                      <p>Initiate your return request within 24 hours of receiving the shipment by emailing us your order number in the subject line, an image of the defective/damaged product, and a brief description of your order at (email id). Requests submitted after this timeframe won't be considered for returns.</p>
                    </li>
                    <li>
                      <p>Upon receipt of your request, we shall examine the same and arrange pickup from your delivery location.</p>
                    </li>
                    <li>
                      <p>Refunds will be processed back to the original source account within 3-7 working days. However, the exact processing time by bank authorities may vary.</p>
                    </li>
                    <li>
                      <p>Please note that EezybreezyU reserves the right to determine if a product is eligible for refund/exchange, and our decision shall be final and binding. Returns that do not meet our policy will not be accepted and will be sent back to the customer.</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="policy">
                <p className="policy-details">
                  For more information on shipment and returns, please don't hesitate to reach out to us at info@EezybreezyU.com.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  <Footer />
  </>
  )
}

export default ShippingReturns
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LeftSidebar from './LeftSidebar';
import Topbar from './Topbar';
import Footer from './Footer';
import { useNavigate, useParams } from "react-router-dom";

const ProductView = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [product, setProduct] = useState(null);
    const [gallery, setGallery] = useState([]); // Fixed typo here

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                const resp = await axios.get(`http://127.0.0.1:8000/api/product_view/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const productData = resp.data.product_list;
                setProduct(productData.product);
                setGallery(productData.images);
            } catch (error) {
                console.error("Error fetching product data", error);
            }
        };

        fetchData();
    }, [id]);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div id="page-top">
            <div id="wrapper">
                <LeftSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Topbar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                        <div className="container-fluid">
                            <div className='col-md-10'>
                                <div className='card'>
                                    <div className='card-header py-3' style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                                        <div className='row'>
                                            <div className='col-md-6 d-flex left-content-end'>
                                                <h6 className="font-weight-bold text-primary left">Edit Product</h6>
                                            </div>
                                            <div className='col-md-6 d-flex justify-content-end'>
                                                <i className="fa fa-arrow-left" onClick={() => navigate(-1)}></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card-body' style={{ overflowY: 'auto' }}>
                                        {product ? (
                                            <div className="form-row text-left">
                                                <div className="form-group col-md-6">
                                                    <p id="product-name"><strong>Product Name:</strong> {product.product_name}</p>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <p id="category-name"><strong>Category Name:</strong> {product.cate_name}</p>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <p><strong>Product Code:</strong> {product.product_code}</p>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <p><strong>Description:</strong> {product.description}</p>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    {gallery.map((image, index) => (
                                                        <div key={index} className="image-container">
                                                            <img 
                                                                src={`http://127.0.0.1:8000/uploads/products/${image.file}`} 
                                                                alt="Gallery" 
                                                                width="200" 
                                                                style={{ cursor: 'pointer' }} 
                                                                onClick={() => window.open(`http://127.0.0.1:8000/uploads/products/${image.file}`, '_blank')}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <p><strong>Size:</strong> {product.size}</p>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <p><strong>Color:</strong> {product.color}</p>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <p><strong>Price:</strong> {product.price}</p>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <p><strong>Other Information:</strong> {product.other_info}</p>
                                                </div>
                                            </div>
                                        ) : (
                                            <p>Loading...</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default ProductView;

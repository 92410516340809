import React, { useEffect, useState } from 'react';
import {useParams, Link, NavLink } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../Config';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { ADD, DLT, REMOVE } from '../redux/actions/action';
import Swal from 'sweetalert2';
import Header from './Header';
import Footer from './Footer';
function Categoryview() {
    const { id } = useParams();
    const token = localStorage.getItem('token');
    const [productList, setProductList] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${BASE_URL}/api/product_category/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setProductList(response.data.product_list);
            } catch (error) {
                console.error('Error fetching product data:', error);
            }
        };
        fetchData();
    }, [id, token, dispatch]);

    const send = (product) => {
        Swal.fire({
            icon: 'success',
            title: `${product.product_name} Item Added to Cart`,
            text: 'Successfully!',
        });
        dispatch(ADD(product));
    };
  return (
   <>
<Header />
<section className="page-title centred">
            <div className="pattern-layer"></div>
            <div className="auto-container">
                <div className="content-box">
                    <h1>All Category</h1>
                    <ul className="bread-crumb clearfix">
                        <li><i className="flaticon-home-1"></i><a href="index.html">Home</a></li>
                        <li>Category</li>
                    </ul>
                </div>
            </div>
        </section>
<section className="shop-section">
            <div className="auto-container">
                <div className="sec-title">
                    <h2>New Arrivals</h2>
                    <p></p>
                    <span className="separator" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets_web/images/icons/separator-1.png)`}}></span>
                </div>
                <div className="sortable-masonry">
                    
                    <div className="items-container row clearfix">
                    {productList.map((product, index) => (
                        <div className="col-lg-3 col-md-6 col-sm-12 shop-block masonry-item small-column best_seller new_arraivals">
                            <div className="shop-block-one">
                                <div className="inner-box">
                                    <figure className="image-box">
                                        <img src={`http://127.0.0.1:8000/uploads/products/${product.images[0]}`} alt="" />
                                        <ul className="info-list clearfix">
                                            <li><a href="index.html"><i className="flaticon-heart"></i></a></li>
                                            <li><Button variant="primary" onClick={() => send(product)} className='col-lg-12'>
                                            Add to Cart
                                        </Button></li>
                                            <li><a href={`http://127.0.0.1:8000/uploads/products/${product.images[0]}`} className="lightbox-image" data-fancybox="gallery"><i className="flaticon-search"></i></a></li>
                                        </ul>
                                    </figure>
                                    <div className="lower-content">
                                    <NavLink to={`/productdetails/${product.id}`}>{product.product_name}</NavLink>
                                        <span className="price">Rs. {product.price}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
               
            </div>
        </section>
        <Footer />
   </> 

  )
}

export default Categoryview
import React from 'react'
import Header from './Header'
import Footer from './Footer'
function TermsCondition() {
  return (
    <>
     <Header />
     <section className="page-title centred"  style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets_web/images/background/page-title.jpg)`}}>
            <div className="pattern-layer"></div>
            <div className="auto-container">
                <div className="content-box">
                    <h1>Terms & Condition</h1>
                    <ul className="bread-crumb clearfix">
                        <li><i className="flaticon-home-1"></i><a href="index.html">Home</a></li>
                        <li>Terms & Condition</li>
                    </ul>
                </div>
            </div>
        </section>
        <section className="about-section">
      <div className="auto-container">
        <div className="row align-items-center clearfix">
          <div className="col-lg-12 col-md-12 col-sm-12 text-column">
            <div className="privacy-section">
              
              <div className="policy">
                <h5 className="intro-heading three">General</h5>
                <p className="policy-details">
                  Welcome to EezybreezyU.in (our "Website" or "Site"), owned by EEZYBREEZYU ("we", "us," and "our"). The following Terms and Conditions ("Terms") govern your use of our Website and its web pages (collectively, our "services"). By accessing and using our Website, you agree to adhere to these Terms.<br /><br />
                  We reserve the right to modify the Terms at any time. Changes and clarifications will take effect immediately upon their posting on the Website. If you do not agree to any of these changes, you must discontinue using the Website.<br /><br />
                  Your access to and use of this website is subject to these Terms and Conditions, as well as applicable laws and regulations. If you do not agree to these terms, you are not allowed to use or access this site. The materials presented on this website are protected by relevant copyright and trademark laws.<br /><br />
                  EezybreezyU is the sole intended beneficiary of this Agreement. In the event of any inconsistency between this Agreement and any other agreement relating to the Goods, this Agreement will prevail. Any modifications, alterations, or amendments to this Agreement require written consent from EezybreezyU. These terms and conditions are governed by the laws of India. EezybreezyU consents to the exclusive jurisdiction of the courts in Noida, Gautam Budh Nagar, Uttar Pradesh, India.
                </p>

                <div className="policy-features">
                  <h5 className="intro-heading three">Trademarks</h5>
                  <ul>
                    <li><p>Unauthorized reproduction or misuse of any trademarks and logos on the Website is strictly prohibited.</p></li>
                    <li><p>EezybreezyU retains all rights to the Trademarks, trade names, and brand names. These marks, names, and associated logos or images are protected by local and international laws and treaties. No license for the use of these marks or names is granted to you under these Terms.</p></li>
                  </ul>
                </div>

                <div className="policy-features">
                  <h5 className="intro-heading three">Intellectual Property Rights:</h5>
                  <ul>
                    <li><p>The content of the Website, including, but not limited to, software, HTML code, scripts, text, artwork, photographs, images, video, and audio, is the intellectual property of EezybreezyU.</p></li>
                    <li><p>EezybreezyU provides this content as a service to its current and prospective customers for personal use and purchasing purposes.</p></li>
                    <li><p>You are authorized to print a copy of any information found on this Website for personal use, unless explicitly prohibited. However, you may not sell information obtained from this Website without our written permission.</p></li>
                    <li><p>Without our prior written consent, you may not copy, modify, reproduce, publish, transmit, distribute, perform, display, create derivative works from, sell, license, or exploit this Site or its content.</p></li>
                    <li><p>Unauthorized copying, alteration, distribution, transmission, performance, display, or use of these materials is not permitted.</p></li>
                    <li><p>Commercial use of this Website or its content, including advertising or revenue generation on your own platform, is prohibited.</p></li>
                  </ul>
                </div>
              </div>

              <div className="policy">
                <div className="policy-features">
                  <h5 className="intro-heading three">Acceptable Use of the Website:</h5>
                  <p>By using our Services, you agree:</p>
                  <ul>
                    <li><p>Not to misuse our Services, using the Website in a way that is unlawful, illegal, fraudulent, or harmful.</p></li>
                    <li><p>Not to perform systematic or automated data collection activities, including scraping, data mining, and data harvesting.</p></li>
                    <li><p>Not to engage in speculative or fraudulent orders.</p></li>
                    <li><p>To take responsibility for all activities on your account and ensure compliance with these Terms.</p></li>
                  </ul>
                </div>
              </div>

              <div className="policy">
                <h5 className="intro-heading three">Termination of Access</h5>
                <p className="policy-details">We reserve the right to terminate access to the Website without prior notice. However, our disclaimer will continue to apply even after such termination.</p>
              </div>

              <div className="policy">
                <div className="policy-features">
                  <h5 className="intro-heading three">Terms of Sale:</h5>
                  <ul>
                    <li><p>When you place an order for goods on our website, you agree to the following terms and conditions. All orders are subject to availability and confirmation of the order price.</p></li>
                    <li><p>Dispatch times may vary based on availability, and estimated delivery times are only indicative. EezybreezyU will not be held responsible for delays caused by postal services.</p></li>
                    <li><p>By placing an order, you confirm that the details you provide are accurate, that you are authorized to use the payment method, and that you have sufficient funds to cover the purchase.</p></li>
                    <li><p>If your order is not approved or items are unavailable, we will notify you immediately by email for a refund or exchange.</p></li>
                    <li><p>Payment shall be payable in full by you according to the due date stated at Checkout. We may pursue legal remedies for collection, including attorney fees, for any unpaid amounts.</p></li>
                  </ul>
                </div>
              </div>

              <div className="policy">
                <div className="policy-features">
                  <h5 className="intro-heading three">Order Related:</h5>
                  <ul>
                    <li><p>The minimum quantity of products that needs to be ordered on the website is 1 piece, and it can be ordered in packs of 1, 3, and 5 pieces, and so on.</p></li>
                    <li><p>If an item is out of stock, customers can still place an order by contacting our team through email or WhatsApp.</p></li>
                    <li><p>For wholesale or bulk orders, contact our team at (email).</p></li>
                    <li><p>We do have product samples available. Contact our team at (email) to know more.</p></li>
                    <li><p>To stay updated on your order status and tracking details, kindly log in to your profile.</p></li>
                    <li><p>Please note: We exclusively offer finished and packed home and car air fresheners.</p></li>
                  </ul>
                </div>
              </div>

              <div className="policy">
                <div className="policy-features">
                  <h5 className="intro-heading three">Disclaimer:</h5>
                  <ul>
                    <li><p>We reserve the right to reject your order in case of payment authorization issues, shipping restrictions, stock unavailability, or quality concerns.</p></li>
                    <li><p>The fabric description on the website is closest to reality. There may be slight variations in color due to screen settings and photography lighting.</p></li>
                  </ul>
                </div>
              </div>

              <div className="policy">
                <div className="policy-features">
                  <h5 className="intro-heading three">Returns &amp; Cancellations:</h5>
                  <ul>
                    <li><p>Returns are possible if the product doesn't match your confirmed order or has genuine quality defects. Minor irregularities in handmade cotton paper products are expected.</p></li>
                    <li><p>For eligible returns, contact customer care with order details and reasons, including product images for defective items. Ensure unused, unworn products with original tags are returned.</p></li>
                    <li><p>Request a return within 48 hours of receiving the item.</p></li>
                    <li><p>Items must be in original condition, packaging, and with tags.</p></li>
                    <li><p>Items must not be worn, washed, or altered.</p></li>
                    <li><p>Returns with makeup stains or perfume smells will not be accepted.</p></li>
                    <li><p>EezybreezyU covers return shipping costs only for manufacturing defects.</p></li>
                    <li><p>After quality checks, credit points are offered within 5-7 business days. Refund is only for manufacturing defects.</p></li>
                    <li><p>We do not accept any cancellations or exchanges; however, we accept returns on manufacturing defects.</p></li>
                  </ul>
                </div>
              </div>

              <div className="policy">
                <p className="policy-details">Contact us at info@EezybreezyU.com for questions, comments, or suggestions. Your feedback is important to us.</p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
     <Footer />
    </>
  )
}

export default TermsCondition
import React, { useState, useEffect } from 'react';
import Swal from "sweetalert2";
import axios from 'axios';
import LeftSidebar from './LeftSidebar';
import Topbar from './Topbar';
import Footer from './Footer';
import { useNavigate } from "react-router-dom";

const Product = () => {
    const navigate = useNavigate();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [categoryParentList, setCategoryParentList] = useState([]);
    const [formData, setFormData] = useState({
        product_code: '',
        product_name: '',
        cate_parent_name: '',
        description: '',
        gallery: [],
        size: [],
        color: '',
        price: '',
        gst_value: '',
        gst: '',
        final_price: '',
        weight: '',
        diamension: '',
        country_of_origin: 'India',
        other_info: ''
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('http://127.0.0.1:8000/api/category_parent_list', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setCategoryParentList(response.data.parent_list);
            } catch (error) {
                console.error("Error fetching category parent list", error);
            }
        };

        fetchData();
    }, []);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleChange = (event) => {
        const { name, value, type, files } = event.target;
        if (type === 'file') {
            const validFiles = Array.from(files).filter(file => file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg');
            if (validFiles.length !== files.length) {
                Swal.fire({
                    icon: "error",
                    title: 'Invalid file type',
                    text: "Only .JPG, .JPEG and .PNG files are allowed.",
                });
            }
            setFormData({
                ...formData,
                [name]: validFiles
            });
        } else if (type === 'checkbox') {
            const newValue = Array.from(event.target.parentNode.parentNode.querySelectorAll(`input[name="${name}"]:checked`)).map(input => input.value);
            setFormData({
                ...formData,
                [name]: newValue,
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const token = localStorage.getItem('token');
        const formDataToSend = new FormData();
        
        // Append all form data including files to formDataToSend
        Object.entries(formData).forEach(([key, value]) => {
            if (key === 'gallery') {
                value.forEach(file => {
                    formDataToSend.append('gallery[]', file); // Use 'gallery[]' for multiple files
                });
            } else if (Array.isArray(value)) {
                value.forEach(val => {
                    formDataToSend.append(key, val);
                });
            } else {
                formDataToSend.append(key, value);
            }
        });
    
        //try {
            const response = await axios.post('http://127.0.0.1:8000/api/product_store', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Important for file upload
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.data.statusCode === 500) {
                Swal.fire({
                    icon: "error",
                    title: `${response.data.errors.product_name}, ${response.data.errors.gallery}`,
                    text: "Try again!",
                });
            }
            else if (response.data.statusCode === 200) {
                Swal.fire({
                    icon: "success",
                    title: `${response.data.name} Category Added`,
                    text: "Successfully!",
                });
                // Clear form data after successful submission
                setFormData({
                    product_code: '',
                    product_name: '',
                    cate_parent_name: '',
                    description: '',
                    gallery: [],
                    size: [],
                    color: '',
                    price: '',
                    gst_value: '',
                    gst: '',
                    final_price: '',
                    weight: '',
                    diamension: '',
                    country_of_origin: 'India',
                    other_info: ''
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: 'Failed to save data',
                    text: "An error occurred while saving data.",
                });
            }
       // } catch (error) {
           // console.error("Error in submitting form:", error);
            //Swal.fire({
               // icon: "error",
               // title: 'Error',
               // text: "Failed to submit form. Please try again later.",
           // });
       // }
    };
    
    

    return (
        <div>
            <div id="page-top">
                <div id="wrapper">
                    <LeftSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <Topbar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                            <div className="container-fluid">
                                <div className="col-md-10">
                                    {successMessage && (
                                        <div className="alert alert-success mt-3" role="alert">
                                            {successMessage}
                                            <button type="button" className="close" onClick={() => setSuccessMessage('')} aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    )}
                                    {errorMessage && (
                                        <div className="alert alert-danger mt-3" role="alert">
                                            {errorMessage}
                                            <button type="button" className="close" onClick={() => setErrorMessage('')} aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className='col-md-10'>
                                    <div className='card'>
                                        <div className='card-header py-3' style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                                            <div className='row'>
                                                <div className='col-md-6 d-flex left-content-end'>
                                                    <h6 className="font-weight-bold text-primary left">Add Product</h6>
                                                </div>
                                                <div className='col-md-6 d-flex justify-content-end'>
                                                    <i className="fa fa-arrow-left" onClick={() => navigate(-1)}></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-body' style={{ overflowY: 'auto' }}>
                                            <form onSubmit={handleSubmit} encType="multipart/form-data">
                                                <div className="form-row text-left">
                                                    <div className="form-group col-md-6">
                                                        <label>Product Code</label>
                                                        <input
                                                            type='text'
                                                            id="product_code"
                                                            name='product_code'
                                                            className='form-control input-lg mb-3'
                                                            value={formData.product_code}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                
                                                    <div className="form-group col-md-6">
                                                        <label>Product Name</label>
                                                        <input
                                                            type='text'
                                                            id="product_name"
                                                            name='product_name'
                                                            className='form-control input-lg mb-3'
                                                            value={formData.product_name}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-row text-left">
                                                    <div className="form-group col-md-6">
                                                        <label>Category</label>
                                                        <select
                                                            className="form-select"
                                                            aria-label="Default select example"
                                                            name="cate_parent_name"
                                                            id="cate_parent_name"
                                                            value={formData.cate_parent_name}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="0">Product Category</option>
                                                            {categoryParentList.map((list) => (
                                                                <option key={list.id} value={list.id}>
                                                                    {list.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                
                                                    <div className="form-group col-md-6">
                                                        <label>Description</label>
                                                        <textarea
                                                            id="description"
                                                            name='description'
                                                            className='form-control input-lg mb-3'
                                                            value={formData.description}
                                                            onChange={handleChange}
                                                            cols="10" rows="3"
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                <div className="form-row text-left">
                                                    <div className="form-group col-md-6">
                                                        <label>Upload Images</label>
                                                        <input
                                                            type='file'
                                                            id="gallery"
                                                            name='gallery'
                                                            className='form-control input-lg mb-3'
                                                            multiple
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label>Size</label>
                                                        <div className='row'>
                                                            <div className='col-md-2'>
                                                                <input type="checkbox" id="size[]" name="size[]" value="S" onChange={handleChange}/>&nbsp;  
                                                                <label>S</label>
                                                            </div>
                                                            <div className='col-md-2'>
                                                                <input type="checkbox" id="size[]" name="size[]" value="M" onChange={handleChange}/> &nbsp;
                                                                <label>M</label> 
                                                            </div>
                                                            <div className='col-md-2'>
                                                                <input type="checkbox" id="size[]" name="size[]" value="L" onChange={handleChange}/> &nbsp;
                                                                <label>L</label>
                                                            </div>
                                                            <div className='col-md-2'>
                                                                <input type="checkbox" id="size[]" name="size[]" value="XL" onChange={handleChange}/> &nbsp;
                                                                <label>XL</label>
                                                            </div>
                                                            <div className='col-md-2'>
                                                                <input type="checkbox" id="size[]" name="size[]" value="XLL" onChange={handleChange}/> &nbsp;
                                                                <label>XLL</label>
                                                            </div>
                                                            <div className='col-md-2'>
                                                                <input type="checkbox" id="size[]" name="size[]" value="XXL" onChange={handleChange}/> &nbsp;
                                                                <label>XXL</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-row text-left">
                                                    <div className="form-group col-md-6">
                                                        <label>Color</label>
                                                        <input
                                                            type='text'
                                                            id="color"
                                                            name='color'
                                                            className='form-control input-lg mb-3'
                                                            value={formData.color}
                                                            onChange={handleChange}
                                                        />
                                                               
                                                                
                                                            
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label>MRP</label>
                                                        <input
                                                            type='text'
                                                            id="price"
                                                            name='price'
                                                            className='form-control input-lg mb-3'
                                                            value={formData.price}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-row text-left">
                                                    <div className="form-group col-md-6">
                                                        <label>GST Value</label>
                                                        <input
                                                            type='text'
                                                            id="gst_value"
                                                            name='gst_value'
                                                            className='form-control input-lg mb-3'
                                                            value={formData.gst_value}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label>GST</label>
                                                        <select
                                                            className="form-select"
                                                            aria-label="Default select example"
                                                            name="gst"
                                                            id="gst"
                                                            value={formData.gst}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="Included">Included</option>
                                                            <option value="Excluded">Excluded</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-row text-left">
                                                    <div className="form-group col-md-6">
                                                        <label>Final Price</label>
                                                        <input
                                                            type='text'
                                                            id="final_price"
                                                            name='final_price'
                                                            className='form-control input-lg mb-3'
                                                            value={formData.final_price}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    {/* <div className="form-group col-md-6">
                                                        <label>Weight</label>
                                                        <input
                                                            type='text'
                                                            id="weight"
                                                            name='weight'
                                                            className='form-control input-lg mb-3'
                                                            value={formData.weight}
                                                            onChange={handleChange}
                                                        />
                                                    </div> */}
                                               
                                                {/* <div className="form-row text-left">
                                                    <div className="form-group col-md-6">
                                                        <label>Dimension</label>
                                                        <input
                                                            type='text'
                                                            id="diamension"
                                                            name='diamension'
                                                            className='form-control input-lg mb-3'
                                                            value={formData.diamension}
                                                            onChange={handleChange}
                                                        />
                                                    </div> */}
                                                    {/* <div className="form-group col-md-6">
                                                        <label>Country of Origin</label>
                                                        <select
                                                            className="form-select"
                                                            aria-label="Default select example"
                                                            name="country_of_origin"
                                                            id="country_of_origin"
                                                            value={formData.country_of_origin}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="India">India</option>
                                                        </select>
                                                    </div> 
                                                </div>*/}
                                                
                                                    <div className="form-group col-md-6">
                                                        <label>Other Information</label>
                                                        <textarea
                                                            id="other_info"
                                                            name='other_info'
                                                            className='form-control input-lg mb-3'
                                                            value={formData.other_info}
                                                            onChange={handleChange}
                                                            cols="10" rows="3"
                                                        ></textarea>
                                                   
                                                </div>
                                                </div>
                                                <button type='submit' className='btn btn-primary left-content-end'>
                                                    Submit
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    <Footer />
                </div>
            </div></div>
            <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up"></i>
            </a>
        </div>
    );
};

export default Product;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginCustomer } from '../redux/actions/customerActions';
import { useNavigate } from 'react-router-dom';

const ExistingCustomer = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const customer = useSelector((state) => state.customer);

    const handleLogin = (e) => {
        e.preventDefault();
        dispatch(loginCustomer({ email, password }, navigate));
    };

    return (
        <div className="existing-customer-container">
            <h3>Returning Customer Login</h3>
            <form onSubmit={handleLogin}>
                <label>Email:</label>
                <input 
                    type="email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    required 
                />
                
                <label>Password:</label>
                <input 
                    type="password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    required 
                />
                
                <button type="submit">Login</button>
            </form>
            {/* Check if customer exists and has an error property */}
            {customer && customer.error && (
                <p style={{ color: 'red' }}>{customer.error}</p>
            )}
        </div>
    );
};

export default ExistingCustomer;

import React, { useState, useEffect } from 'react';
import Swal from "sweetalert2";
import axios from 'axios';
import LeftSidebar from './LeftSidebar';
import Topbar from './Topbar';
import Footer from './Footer';
import { useNavigate } from "react-router-dom";

const CategorySub = () => {
const navigate = useNavigate();
const [isSidebarOpen, setIsSidebarOpen] = useState(false);
const [successMessage, setSuccessMessage] = useState('');
const [errorMessage, setErrorMessage] = useState('');
const [formData, setFormData] = useState({
    category_name: '',
    cate_parent_name: '',
});
    const [categoryParentList, setCategoryParentList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('http://127.0.0.1:8000/api/category_parent_list', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setCategoryParentList(response.data.parent_list);
            } catch (error) {
                console.error("Error fetching category parent list", error);
            }
        };

        fetchData();
    }, []);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const token = localStorage.getItem('token');
            const formDataToSend = new FormData();
            Object.keys(formData).forEach(key => {
                formDataToSend.append(key, formData[key]);
            });

            const response = await axios.post('http://127.0.0.1:8000/api/sub_category_store', formDataToSend, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.data.statusCode === 200) {
                Swal.fire({
                    icon: "success",
                    title: `${response.data.name} Category Added`,
                    text: "Successfully!",
                });
            } else if (response.data.statusCode === 500) {
                Swal.fire({
                    icon: "error",
                    title: response.data.status,
                    text: "Failed!",
                });
            } else if (response.data.statusCode === 422) {
                Swal.fire({
                    icon: "error",
                    title: response.data.status,
                    text: "Try another name!",
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: 'Other reason',
                    text: "Failed!",
                });
            }

            setSuccessMessage(response.data.message);
            setFormData({
                category_name: '',
                cate_parent_name: '',
            });
        } catch (error) {
            console.error("Error submitting form", error);
            setErrorMessage('An error occurred. Please try again.');
        }
    };

    return (
        <div>
            <div id="page-top">
                <div id="wrapper">
                    <LeftSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <Topbar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                            <div className="container-fluid">
                                <div className="col-md-10">
                                    {successMessage && (
                                        <div className="alert alert-success mt-3" role="alert">
                                            {successMessage}
                                            <button type="button" className="close" onClick={() => setSuccessMessage('')} aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    )}
                                    {errorMessage && (
                                        <div className="alert alert-danger mt-3" role="alert">
                                            {errorMessage}
                                            <button type="button" className="close" onClick={() => setErrorMessage('')} aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className='col-md-10'>
                                    <div className='card'>
                                        <div className='card-header py-3' style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                                            <div className='row'>
                                                <div className='col-md-6 d-flex left-content-end'>
                                                    <h6 className="font-weight-bold text-primary left">Add Sub Category</h6>
                                                </div>
                                                <div className='col-md-6 d-flex justify-content-end'>
                                                    <i className="fa fa-arrow-left" onClick={() => navigate(-1)}></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-body' style={{ overflowY: 'auto' }}>
                                            <form onSubmit={handleSubmit}>
                                                <select
                                                    className="form-select"
                                                    aria-label="Default select example"
                                                    name="cate_parent_name"
                                                    id="cate_parent_name"
                                                    value={formData.cate_parent_name}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Select</option>
                                                    {categoryParentList.map((list) => (
                                                        <option key={list.id} value={list.id}>
                                                            {list.name}
                                                        </option>
                                                    ))}
                                                </select><br />

                                                <input
                                                    type='text'
                                                    id="category_name"
                                                    name='category_name'
                                                    className='form-control input-lg mb-3'
                                                    placeholder='Category Name'
                                                    value={formData.category_name}
                                                    onChange={handleChange}
                                                />
                                                <button type='submit' className='btn btn-primary left-content-end'>Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
            <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up"></i>
            </a>
        </div>
    );
};

export default CategorySub;

import React from 'react'
import Header from './Header'
import Footer from './Footer'
function Contact() {
  return (
    <>
    <Header />
    <section className="page-title centred"  style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets_web/images/background/page-title.jpg)`}}>
            <div className="pattern-layer"></div>
            <div className="auto-container">
                <div className="content-box">
                    <h1>Contact</h1>
                    <ul className="bread-crumb clearfix">
                        <li><i className="flaticon-home-1"></i><a href="index.html">Home</a></li>
                        <li>Contact</li>
                    </ul>
                </div>
            </div>
        </section>
        <section className="address-section">
        <div className="auto-container">
          <div className="sec-title">
            <h2>Our Addresses</h2>
           
            <span
              className="separator"
              style={{ backgroundImage: 'url(assets/images/icons/separator-1.png)' }}
            ></span>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 address-column">
              <div className="single-adderss-block">
                <div className="inner-box text-center">
                  <i
                    className="fas fa-map-marker-alt"
                    style={{ fontSize: '30px', margin: '0px 0 16px' }}
                  ></i>
                  <p>
                    Key Solutions, 208, First Floor, Jaipuria Plaza, Sector - 26, Noida - 201301,
                    INDIA
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 address-column">
              <div className="single-adderss-block">
                <div className="inner-box text-center">
                  <i className="fas fa-phone" style={{ fontSize: '30px', margin: '0px 0 16px' }}></i>
                  <p style={{textAlign:'center'}}>+880171889547</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 address-column">
              <div className="single-adderss-block">
                <div className="inner-box text-center">
                  <i
                    className="fas fa-envelope"
                    style={{ fontSize: '30px', margin: '0px 0 16px' }}
                  ></i>
                  <p style={{textAlign:'center'}}>
                    <a href="mailto:info@example.com">info@example.com</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-section">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="contact-map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.7744946479424!2d77.33246797528692!3d28.576533575695436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5b286157b33%3A0xf0b2a756da1edcf5!2sKey%20Solutions!5e0!3m2!1sen!2sin!4v1723111123503!5m2!1sen!2sin"
                  width="450"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="location-map"
                ></iframe>
              </div>
            </div>
            <div className="col-md-7">
              <div className="sec-title">
                <h2>Get In Touch</h2>
                <p>Excepteur sint occaecat cupidatat non proident sunt</p>
                <span
                  className="separator"
                  style={{ backgroundImage: 'url(assets/images/icons/separator-1.png)' }}
                ></span>
              </div>
              <div className="form-inner">
                <form method="post" action="sendemail.php" id="contact-form" className="default-form">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                      <input type="text" name="username" placeholder="Name" required />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                      <input type="email" name="email" placeholder="Email" required />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                      <input type="text" name="subject" placeholder="Subject" required />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                      <input type="text" name="phone" placeholder="Phone" required />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                      <textarea name="message" placeholder="Message"></textarea>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn centred">
                      <button type="submit" className="theme-btn-two" name="submit-form">
                        Submit Message<i className="flaticon-right-1"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    <Footer />
    </>
  )
}

export default Contact
import React from 'react';
import { useSelector } from 'react-redux';

const Profiles = () => {
    const customer = useSelector((state) => state.customer);

    return (
        <div className="profile-container">
            <h3>Welcome, {customer.name}</h3>
            <p>Email: {customer.email}</p>
            <h4>Recent Orders</h4>
            <ul>
                {customer.orders.map((order) => (
                    <li key={order.id}>Order #{order.id} - Total: {order.total}</li>
                ))}
            </ul>
        </div>
    );
};

export default Profiles;

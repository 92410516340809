import React, { useEffect, useState } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';
import { ADD } from '../redux/actions/action';
import Swal from 'sweetalert2';
import axios from 'axios';
import { BASE_URL } from '../Config';
function Product() {
    const { id } = useParams();
    const token = localStorage.getItem('token'); // Use token from localStorage
    const [productList, setProductList] = useState([]);
    const dispatch = useDispatch();

    // Fetch product data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/product_list`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setProductList(response.data.product_list);
            } catch (error) {
                console.error('Error fetching product data:', error);
            }
        };
        fetchData();
    }, [id, token]);

    const send = (product) => {
        const quantity = 1;  // Set the default quantity for the product

        // Display success alert when item is added to cart
        Swal.fire({
            icon: 'success',
            title: `${product.product_name} Item Added to Cart`,
            text: 'Successfully!',
        });

        // Create a new product object with quantity
        const productWithQuantity = { ...product, qnty: quantity };

        // Dispatch action to add product to the cart
        dispatch(ADD(productWithQuantity));
    };
    
    return (
        <section className="shop-section">
            <div className="auto-container">
                <div className="sec-title">
                    <h2>New Arrivals</h2>
                    <span className="separator" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets_web/images/icons/separator-1.png)` }}></span>
                </div>
                <div className="sortable-masonry">
                    <div className="items-container row clearfix">
                        {productList.map((product) => (
                            <div key={product.id} className="col-lg-3 col-md-6 col-sm-12 shop-block masonry-item small-column best_seller new_arraivals">
                                <div className="shop-block-one">
                                    <div className="inner-box">
                                        <figure className="image-box">
                                            <img src={`${BASE_URL}/uploads/products/${product.images[0]}`} alt={product.product_name} />
                                            <ul className="info-list clearfix">
                                                <li><a href="index.html"><i className="flaticon-heart"></i></a></li>
                                                <li>
                                                    <Button variant="primary" onClick={() => send(product)} className="col-lg-12">
                                                        Add to Cart
                                                    </Button>
                                                </li>
                                                <li>
                                                    <a href={`${BASE_URL}/uploads/products/${product.images[0]}`} className="lightbox-image" data-fancybox="gallery">
                                                        <i className="flaticon-search"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </figure>
                                        <div className="lower-content">
                                            <NavLink to={`/productdetails/${product.id}`}>{product.product_name}</NavLink>
                                            <span className="price">Rs. {product.price}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="more-btn centred">
                    <a href="shop.html" className="theme-btn-one">View All Products<i className="flaticon-right-1"></i></a>
                </div>
            </div>
        </section>
    );
}

export default Product;

import LeftSidebar from './LeftSidebar';
import React, { useState, useEffect } from 'react';
import Topbar from './Topbar';
import Footer from './Footer';
import Spinner from 'react-bootstrap/Spinner';

 const Customer = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    
    const [abstractData, setAbstractData] = useState({ columns: [], rows: [] });

    useEffect(() => {
      // Simulate a data fetch
      setTimeout(() => {
        setAbstractData({
          columns: [{ id: 'name', label: 'Name' }, { id: 'email', label: 'Email' }],
          rows: [{ name: 'John Doe', email: 'john@example.com' }, { name: 'Jane Smith', email: 'jane@example.com' }]
        });
      }, 2000);
    }, []);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    return (
        <div>
            <div id="page-top">
                <div id="wrapper">
                    <LeftSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <Topbar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                            <div className="container-fluid">
                          
            <table className="table">
              <thead>
                <tr>
                  {abstractData.columns.map(column => (
                    <th key={column.id}>{column.label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {abstractData.rows.map((row, index) => (
                  <tr key={index}>
                    {abstractData.columns.map(column => (
                      <td key={column.id}>{row[column.id]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
         
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
            <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up"></i>
            </a>
        </div>
    );
}
export default Customer;
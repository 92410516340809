import React, { useState, useEffect } from 'react';
import Swal from "sweetalert2";
import axios from 'axios';
import LeftSidebar from './LeftSidebar';
import Topbar from './Topbar';
import Footer from './Footer';
import { useNavigate } from "react-router-dom";

const SizeUnit = () => {
    const navigate = useNavigate();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [units, setUnits] = useState([]);
    const [formData, setFormData] = useState({
        unit: '',
        name: ''
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                
                const response = await axios.get('http://127.0.0.1:8000/api/unit_list', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setUnits(response.data.unit_list);
            } catch (error) {
                console.error("Error fetching unit list", error);
            }
        };

        fetchData();
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post('http://127.0.0.1:8000/api/size_store', formData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            
            if (response.data.statusCode === 422) {
                Swal.fire({
                    icon: "error",
                    title: `${response.data.errors.name}`,
                    text: "Try again!",
                });
            } else if (response.data.statusCode === 200) {
                Swal.fire({
                    icon: "success",
                    title: `${response.data.name} Size Added`,
                    text: "Successfully!",
                });
                setFormData({
                    unit: '',
                    name: ''
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: 'Failed to save data',
                    text: "An error occurred while saving data.",
                });
            }
        } catch (error) {
            console.error("Error submitting form", error);
            Swal.fire({
                icon: "error",
                title: 'Failed to save data',
                text: "An error occurred while saving data.",
            });
        }
    };

    return (
        <div>
            <div id="page-top">
                <div id="wrapper">
                    <LeftSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <Topbar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                            <div className="container-fluid">
                                <div className="col-md-10">
                                    {successMessage && (
                                        <div className="alert alert-success mt-3" role="alert">
                                            {successMessage}
                                            <button type="button" className="close" onClick={() => setSuccessMessage('')} aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    )}
                                    {errorMessage && (
                                        <div className="alert alert-danger mt-3" role="alert">
                                            {errorMessage}
                                            <button type="button" className="close" onClick={() => setErrorMessage('')} aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className='col-md-10'>
                                    <div className='card'>
                                        <div className='card-header py-3' style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                                            <div className='row'>
                                                <div className='col-md-6 d-flex left-content-end'>
                                                    <h6 className="font-weight-bold text-primary left">Unit Size</h6>
                                                </div>
                                                <div className='col-md-6 d-flex justify-content-end'>
                                                    <i className="fa fa-arrow-left" onClick={() => navigate(-1)}></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-body' style={{ overflowY: 'auto' }}>
                                            <form onSubmit={handleSubmit} encType="multipart/form-data">
                                                <div className="form-row text-left">
                                                    <div className="form-group col-md-12">
                                                        <label>Unit</label>
                                                        <select name="unit" id="unit" className='form-control' value={formData.unit} onChange={handleChange}>
                                                            <option value="">Select</option>
                                                            {units.map((list) => (
                                                                <option key={list.id} value={list.name}>
                                                                    {list.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <label>Size</label>
                                                        <input
                                                            type='text'
                                                            id="name"
                                                            name='name'
                                                            className='form-control input-lg mb-3'
                                                            value={formData.name}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <button type='submit' className='btn btn-primary left-content-end'>
                                                    Submit
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
                <a className="scroll-to-top rounded" href="#page-top">
                    <i className="fas fa-angle-up"></i>
                </a>
            </div>
        </div>
    );
};

export default SizeUnit;

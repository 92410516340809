import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from './Header';
import axios from 'axios';
import Footer from './Footer'; 
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { ADD, DLT, REMOVE } from '../redux/actions/action';
import Swal from 'sweetalert2';
import Bannerslider from './Bannerslider';
import Product from './Product';
import { BASE_URL } from '../Config';

function Home() {

    const { id } = useParams();
    const token = localStorage.getItem('token');
    const [productList, setProductList] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${BASE_URL}/api/product_list`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setProductList(response.data.product_list);
            } catch (error) {
                console.error('Error fetching product data:', error);
            }
        };
        fetchData();
    }, [id, token, dispatch]);

    const send = (product) => {
        Swal.fire({
            icon: 'success',
            title: `${product.product_name} Item Added to Cart`,
            text: 'Successfully!',
        });
        dispatch(ADD(product));
    };

    

    return (
        <>
            <Header />
            <Bannerslider />
            <div className="boxed_wrapper">
            <section className="collection-section" style={{ marginTop: "20px"}}>
            <div className="auto-container">
                <div className="row clearfix">
                    <div className="col-lg-6 col-md-6 col-sm-12 single-column">
                        <div className="single-item wow fadeInLeft animated animated animated animated" data-wow-delay="00ms" data-wow-duration="1500ms" style={{ visibility: "visible", animationDuration: "1500ms", animationDelay: "0ms", animationName: "fadeInLeft" }}>
                            <div className="inner-box" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets_web/images/banner/syle-bg-one.jpg)` }}>
                                <h2>Home Fragrances </h2>
                                <p>World Best Fragrances</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 single-column">
                        <div className="single-item wow fadeInRight animated animated animated animated" data-wow-delay="00ms" data-wow-duration="1500ms" style={{ visibility: "visible", animationDuration: "1500ms", animationDelay: "0ms", animationName: "fadeInRight"}}>
                            <div className="inner-box" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets_web/images/banner/style-bg-two.jpg)`}}>
                                <h2>Car Fragrances</h2>
                                <p>World Best Fragrances</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="topcategory-section centred">
            <div className="auto-container">
                <div className="sec-title">
                    <h2>Top Category</h2>
                    <p></p>
                    <span className="separator" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets_web/images/icons/separator-1.png)`}}></span>
                </div>
                <div className="row clearfix">
                    <div className="col-lg-2 col-md-6 col-sm-12 category-block">
                        <div className="category-block-one wow fadeInUp animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                            <figure className="image-box"><img src="assets_web/images/resource/animation.png" alt="" /></figure>
                            <h5><a href="index.html">Animation</a></h5>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-12 category-block">
                        <div className="category-block-one wow fadeInUp animated animated" data-wow-delay="200ms" data-wow-duration="1500ms">
                            <figure className="image-box"><img src="assets_web/images/resource/astro.png" alt="" /></figure>
                            <h5><a href="index.html">Astro</a></h5>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-12 category-block">
                        <div className="category-block-one wow fadeInUp animated animated" data-wow-delay="400ms" data-wow-duration="1500ms">
                            <figure className="image-box"><img src="assets_web/images/resource/fruits.png" alt="" /></figure>
                            <h5><a href="index.html">fruits</a></h5>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-12 category-block">
                        <div className="category-block-one wow fadeInUp animated animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                            <figure className="image-box"><img src="assets_web/images/resource/hoilday.png" alt="" /></figure>
                            <h5><a href="index.html">Hoildays</a></h5>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-12 category-block">
                        <div className="category-block-one wow fadeInUp animated animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                            <figure className="image-box"><img src="assets_web/images/resource/Quotations.png" alt="" /></figure>
                            <h5><a href="index.html">Quotations</a></h5>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-12 category-block">
                        <div className="category-block-one wow fadeInUp animated animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                            <figure className="image-box"><img src="assets_web/images/resource/Seasons.png" alt="" /></figure>
                            <h5><a href="index.html">Seasons</a></h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Product />
        <section className="service-section">
            <div className="auto-container">
                <div className="inner-container">
                    <div className="row clearfix">
                        <div className="col-lg-3 col-md-6 col-sm-12 service-block">
                            <div className="service-block-one">
                                <div className="inner-box">
                                    <div className="icon-box"><i className="flaticon-truck"></i></div>
                                    <h3><a href="index.html">Free Shipping</a></h3>
                                    <p>Free shipping on oder over $100</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 service-block">
                            <div className="service-block-one">
                                <div className="inner-box">
                                    <div className="icon-box"><i className="flaticon-credit-card"></i></div>
                                    <h3><a href="index.html">Secure Payment</a></h3>
                                    <p>We ensure secure payment with PEV</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 service-block">
                            <div className="service-block-one">
                                <div className="inner-box">
                                    <div className="icon-box"><i className="flaticon-24-7"></i></div>
                                    <h3><a href="index.html">Support 24/7</a></h3>
                                    <p>Contact us 24 hours a day, 7 days a week</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 service-block">
                            <div className="service-block-one">
                                <div className="inner-box">
                                    <div className="icon-box"><i className="flaticon-undo"></i></div>
                                    <h3><a href="index.html">30 Days Return</a></h3>
                                    <p>Simply return it within 30 days for an exchange.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </div>
            <Footer />
        </>
    );
}

export default Home;

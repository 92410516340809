import React, { useEffect, useState } from 'react'
import SimpleImageSlider from "react-simple-image-slider";
function Bannerslider() {

    const images = [
        {
            url: "assets_web/images/banner/banner1.jpg",
            heading: "Welcome to Our Site",
            text: "Discover amazing products and services",
            buttonText: "Shop Now"
        },
        {
            url: "assets_web/images/banner/banner2.jpg",
            heading: "Summer Sale",
            text: "Up to 50% off on selected items",
            buttonText: "Explore Offers"
        },
        {
            url: "assets_web/images/banner/banner3.jpg",
            heading: "Summer Sale",
            text: "Up to 50% off on selected items",
            buttonText: "Explore Offers"
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    const onSlideChange = (index) => {
        setCurrentIndex(index - 1);
    };
  return (
    <>
     <div className="slider-container">
                <SimpleImageSlider
                    width={1519}
                    height={600}
                    images={images.map(img => ({ url: img.url }))}
                    showBullets={false}
                    showNavs={true}
                    onStartSlide={onSlideChange}
                    autoPlay={true} 
                    autoPlayDelay={3.0} 
                />
                <div className='caption' style={{position: "absolute",top: "43%",left: "6%"}}>
                <div className="slider-overlay banner">
                    <h2>{images[currentIndex].heading}</h2>
                    <p>{images[currentIndex].text}</p>
                    <button>{images[currentIndex].buttonText}</button>
                </div>
                </div>
            </div>
    </>
  )
}

export default Bannerslider
// src/redux/reducers/index.js

import { combineReducers } from 'redux';
import customerReducer from './customerReducer';
import { cartreducer } from './reducer';

const rootReducer = combineReducers({
    cartreducer,
    customer: customerReducer,
    // other reducers can go here
});

export default rootReducer;

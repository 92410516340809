import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Login = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: "",
        password: ""
    });
    const [validationErrors, setValidationErrors] = useState({});

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post("http://127.0.0.1:8000/api/login", formData);
            const token = response.data.authorisation.token; // Correctly access the token
           // console.log(token);
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(response.data.user));
            localStorage.setItem('role', response.data.user.role);
            const role = response.data.user.role;

            Swal.fire({
                icon: "success",
                title: "Login Successful",
                text: "Welcome back!",
            }).then(() => {
                if (role === 3) {
                    navigate("/admin");
                } else if (role === 2) {
                    
                } else {
                   
                }
            });
        } catch (error) {
            if (error.response && error.response.status === 401) {
                Swal.fire({
                    icon: "error",
                    title: "Login Failed",
                    text: "Invalid email or password. Please try again.",
                });
            } else {
                const responseData = error.response.data;
                setValidationErrors(responseData);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Something went wrong. Please try again.",
                });
            }
        }
    };

    return (
        <>
         <link href={`${process.env.PUBLIC_URL}/assets/css/sb-admin-2.min.css`} rel="stylesheet" />
         <link href={`${process.env.PUBLIC_URL}/assets/vendor/fontawesome-free/css/all.min.css`} />
        
        <div className='bg-gradient-primary' style={{ height: '100vh' }}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className='card o-hidden border-0 shadow-lg my-5'>
                            <div className='card-body p-0'>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="p-5">
                                            <div className="text-left">
                                                <h1 className="h4 text-gray-900 mb-4">Login</h1><hr />
                                            </div>
                                            <form className="user" method='POST' onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        placeholder="Enter Email"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                    />
                                                    {validationErrors.email && (
                                                        <span className="text-danger">{validationErrors.email[0]}</span>
                                                    )}
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        placeholder="Enter Password"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                    />
                                                    {validationErrors.password && (
                                                        <span className="text-danger">{validationErrors.password[0]}</span>
                                                    )}
                                                </div>
                                                <div className="text-left">
                                                    <button className="btn btn-primary btn-user">
                                                        Login
                                                    </button>
                                                </div>
                                            </form>
                                            <hr />
                                            <div className="text-center">
                                                <p className="text-center text-muted mt-5 mb-0">
                                                    <Link to="/forgotpassword">Forgot Password?</Link> | Not an account? <Link to="/register" className="fw-bold text-body"><u>Register here</u></Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Login;

import { Link,NavLink} from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import Badge from '@mui/material/Badge';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap/esm/Table';
import { DLT } from '../redux/actions/action';
import Menu from '@mui/material/Menu';
import Button from 'react-bootstrap/Button';
import Navbar from './Navbar';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
function Header() {
    const [price,setPrice] = useState(0);
    // console.log(price);

        const getdata = useSelector((state)=> state.cartreducer.carts);
        // console.log(getdata);

        const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const dlt = (id)=>{
        dispatch(DLT(id))
    }


    const total = ()=>{
        let price = 0;
        getdata.map((ele,k)=>{
            price = ele.price * ele.qnty + price
        });
        setPrice(price);
    };

    useEffect(()=>{
        total();
    },[total])

   
    
  return (
    <>
 
    <link href="https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" />

   
    <Navbar />
        <div className="mobile-menu">
            <div className="menu-backdrop"></div>
            <div className="close-btn"><i className="fas fa-times"></i></div>
            <nav className="menu-box">
                <div className="nav-logo"><a href="index.html"><img src="../assets_web/images/logo-2.png" alt="" title="" /></a></div>
                <div className="menu-outer"></div>
                <div className="contact-info">
                    <h4>Contact Info</h4>
                    <ul>
                        <li>Chicago 12, Melborne City, USA</li>
                        <li><a href="tel:+8801682648101">+88 01682648101</a></li>
                        <li><a href="mailto:info@example.com">info@example.com</a></li>
                    </ul>
                </div>
                <div className="social-links">
                    <ul className="clearfix">
                        <li><a href="index.html"><span className="fab fa-twitter"></span></a></li>
                        <li><a href="index.html"><span className="fab fa-facebook-square"></span></a></li>
                        <li><a href="index.html"><span className="fab fa-pinterest-p"></span></a></li>
                        <li><a href="index.html"><span className="fab fa-instagram"></span></a></li>
                        <li><a href="index.html"><span className="fab fa-youtube"></span></a></li>
                    </ul>
                </div>
            </nav>
        </div>
       

        
    </>
  )
}

export default Header